<template>
<div>
    <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>费用中心</el-breadcrumb-item>
            <el-breadcrumb-item>收支明细</el-breadcrumb-item>
        </el-breadcrumb>

  <div class="flow-list-content flexcolumn-row-center">
    <span class="font-size-16 title-text" style="width:95%;margin:1.25rem 0 1.25rem 0">收支明细</span>
    <div class="search-wrapper flex-row-space-between">
      <div class="flexrow-column-center search-box">
        <span class="font-size-14 search-title-text">交易编号：</span>
        <SearchInput class="search-input"
          placeholderStr="请输入交易编号"
          :resetFlag="resetFlag"
          @updateSearchList="updateOrderIdSearchList"
        />
      </div>
      <div class="flexrow-column-center search-box">
        <span class="font-size-14 search-title-text">账单号：</span>
        <SearchInput class="search-input"
          placeholderStr="请输入账单号"
          :resetFlag="resetFlag"
          @updateSearchList="updateBillIdSearchList"     
        />
      </div>
      <div class="flexrow-column-center search-box">
        <span class="font-size-14 search-title-text">收支类型：</span>
        <SearchDropDown class="search-input"
          :options="flowTypeList"
          :resetFlag="resetFlag"
          @dropDownSelect="flowDropDownSelect"
          />
      </div>
      <div class="flexrow-column-center search-box">
        <span class="font-size-14 search-title-text">交易类型：</span>
        <SearchDropDown class="search-input"
          :options="payTypeList"
          :resetFlag="resetFlag"
          @dropDownSelect="payDropDownSelect"
          />
      </div>
      <div class="flexrow-column-center pay-time-picker" style="margin-top:1rem">
        <span class="font-size-14 search-title-text">交易时间：</span>
        <el-date-picker
          class="search-input"
          v-model="dateValue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
    </div>
    <div class="btns-wrppaer flexrow-column-center">
      <!-- <div class="flex-all-center font-size-14 pointer btn-wrapper"
        @click="onClickCheck">查询</div>
         -->
      <div class="flex-all-center font-size-14 pointer btn-wrapper"
        @click="onClickReset">重置</div>
    </div>
     <el-table :data="curList" border style="width: 100%" class="data-table">
      <el-table-column fixed align="center" prop="trade_number" label="交易编号" width="200"></el-table-column>
      <el-table-column align="center" prop="created_at" label="交易时间" width="150"> </el-table-column>
      <el-table-column align="center" label="收支类型">
        <template slot-scope="scope">
          <div v-html="scope.row.typeStr"></div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="pay_typeStr" label="交易类型"> </el-table-column>
      <el-table-column align="center" prop="pay_channel" label="交易渠道"> </el-table-column>
      <el-table-column align="center" prop="account_number" label="账单号" width="200"> </el-table-column>
      <el-table-column align="center" prop="account_period" label="账期" width="150"> </el-table-column>
      <el-table-column align="center" prop="remark" label="交易备注"  min-width="120"> </el-table-column>
      <el-table-column align="center" label="金额">
         <template slot-scope="scope">
          <div v-html="scope.row.price"></div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="balance" label="余额"> </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-size="pageSize"
      :page-sizes="[pageSize]"
      layout="prev, pager, next, sizes, jumper , total"
      :background="true"
      :total="searchList.length">
    </el-pagination>
  </div>
  </div>
</template>

<script>
import request from "@request/api/index";
import SearchInput from '@components/SearchInput';
import SearchDropDown from "@components/SearchDropDown";
import {getFormatDate} from '@utils/dateUtil.js'
import { log } from 'util';
import { setTimeout } from 'timers';

export default {
  name: "FlowList",
  data() {
    return {
      curList:[],
      curPage:1,
      resetFlag: 0,//重置表单组件信号码
      isSearch: true,//是否进行搜索
      orderIdSearchInputText:"",//交易编号搜索字段
      billIdSearchInputText:"",//账单号搜索字段
      flowType:"",//收支类型搜索字段
      payType:"",//交易类型搜索字段
      itemList: [], //收支明细列表
      searchList:[],//过滤后的收支明细列表
      titleList:[{key:'trade_number',value:'交易编号'},
        {key:'created_at',value:'交易时间'},
        {key:'typeStr',value:'收支类型'},
        {key:'pay_typeStr',value:'交易类型'},
        {key:'pay_channel',value:'交易渠道'},
        {key:'account_number',value:'账单号'},
        {key:'account_period',value:'账期'},
        {key:'remark',value:'交易备注'},
        {key:'price',value:'金额'},
        {key:'balance',value:'余额'}],
      flowTypeList:[{key:'', value:'全部'},
        {key:1, value:'支出'},
        {key:2, value:'收入'}],
      payTypeList:[{key:'', value:'全部'},
        {key:1, value:'消费'},
        {key:2, value:'充值'}],
      pageSize:10,//每页显示条数
      dateValue:'',
      pickerOptions: {
        disabledDate: (time) => {
          let curDate = (new Date()).getTime();
          let three = 90 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        }
      },
    };
  },
  watch:{
    dateValue(){
      if(this.dateValue){
        let start_time = getFormatDate(new Date(this.dateValue[0]).getTime());
        let end_time = getFormatDate(new Date(this.dateValue[1]).getTime());
        this.requestFlowList({start_time,end_time});
      }else{
        this.requestFlowList({start_time:'',end_time:''});
      }
    },
    searchList(){
      let page = Math.floor(this.searchList.length / this.pageSize) + 1;
      if(this.searchList.length % this.pageSize == 0){
        page--;
      }
      if(this.curPage > 1 && this.curPage > page){
        this.curPage = page;
      }
      this.computeList();
    }
  },
  created(){
    this.requestFlowList({start_time:'',end_time:''});
  },
  methods: {
    computeList(){
      let start = (this.curPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      this.curList = this.searchList.slice(start,end);
    },
    handleCurrentChange(page){
      this.curPage = page;
      this.computeList();
    },
    requestFlowList(params){
      request.bill.requestFlowList(params).then(res=>{
        this.itemList = res;
        this.filterData();
      }).catch(err=>{
        console.log(err);
      });
    },
    /**
     * 交易编号搜索结果回调
     */
    updateOrderIdSearchList(str) {
      this.orderIdSearchInputText = str;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 账单编号搜索结果回调
     */
    updateBillIdSearchList(str){
      this.billIdSearchInputText = str;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 收支类型选择回调
     */
    flowDropDownSelect(id){
      this.flowType = id;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 交易类型选择回调
     */
    payDropDownSelect(id){
      this.payType = id;
      if(!this.isSearch) return;
      this.filterData();
    },
    onClickCheck(){

    },
    onClickReset(){
      this.resetFlag++;
      this.isSearch = false;
      if(!this.dateValue){
        this.$nextTick(()=>{
          this.filterData();
        })
      }
      this.dateValue = "";
    },
    /**
     * 数据过滤
     */
    filterData(){
      let copyList = JSON.parse(JSON.stringify(this.itemList));
      let arr = [];
      for(let i = 0;i<copyList.length;i++){
        if(copyList[i].trade_number.indexOf(this.orderIdSearchInputText) >= 0 && 
          copyList[i].account_number.indexOf(this.billIdSearchInputText) >= 0 && 
          (this.flowType === '' || copyList[i].type == this.flowType) && 
          (this.payType === '' || copyList[i].pay_type == this.payType)){
          arr.push(copyList[i]);
        }
      }
      this.searchList = arr;
      this.isSearch = true;
    }
  },
  components:{
    SearchInput, SearchDropDown
  }
};
</script>

<style lang="scss" scoped>
.flow-list-content {
  width: 100%;
  min-width: 1184px;
  min-height: 900px;
  position: relative;
  background: white;
  border-radius: 8px;
  .title-text{
    color: black;
    font-weight: 600;
  }
  .search-wrapper{
    width:95%;
    height:auto;
    min-width: 1120px;
    flex-wrap: wrap;
    .search-box{
      width:23%;
      min-width:300px;
      .search-title-text{
        color: black;
        max-width: 80px;
        white-space:nowrap;
      }
      .search-input{
        width:74%;
        min-width:220px;
        height:32px;
      }
    }
    .pay-time-picker{
      @extend .search-box;
      width:30%;
      min-width:350px
    }
  }
  .btns-wrppaer{
    margin-top: 16px;
    margin-bottom: 20px;
    width:95%;
    .btn-wrapper{
      margin-right: 16px;
      width: 80px;
      height: 36px;
      background: #3468FE;
      border-radius: 4px;
      border: 1px solid #3468FE;
      color: white;
    }
  }
  .pagination-list-wrapper{
    position: relative;
    flex:1;
    width:95%;
    min-width: 1120px;
  }
  ::v-deep .el-input__icon,
  ::v-deep .el-range-separator{
    height:auto;
  }
}

::v-deep.data-table {
  td .cell {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    white-space:nowrap;
  }
  &.el-table th {
    background: #f8f9fb;

    .cell {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
  }
}
.el-table--border::after{
  width: 0;
}
.el-table--border{
  border:none
}
.el-table::before {
  width: 0;
}
</style>