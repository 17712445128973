<template>
  <div class="flexrow-column-center">
    <el-select v-model="key" placeholder="全部" class="select-option">
      <el-option
       class="select-option"
        v-for="item in options"
        :key="item.key"
        :label="item.value"
        :value="item.key">
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    resetFlag: Number, // 控制 重置表单 信号码
    options:Array
  },
  data() {
    return {
      key:'',
    };
  },
  watch: {
    key(){
      this.$emit('dropDownSelect',this.key);
    },
    resetFlag(){
      this.key = ""
    }
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.search-dropdown-wrapper {
  position: relative;

  
}
::v-deep .el-select,
::v-deep .el-input,
::v-deep .el-input__inner{
  width:100%;
  height:32px;
}

::v-deep .el-input__icon{
  line-height: 32px;
}
</style>